import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const AppRouter = () => {
    return <Router>
        <Switch>
            <Route path = '*' component={() => { window.location = 'https://www.albinacharitabletrust.com'; return null;} }/>
        </Switch>
    </Router>
}

export default AppRouter
